import React, { useState, useEffect } from "react";
import "./styles.css";

function App() {
  const [user, setUser] = useState({ avatar: "", name: "" });
  const [monBalance, setMonBalance] = useState(null);  // 用来存储用户的mon_balance

  useEffect(() => {
    if (window.Telegram && window.Telegram.WebApp) {
      // 初始化 WebApp
      window.Telegram.WebApp.ready();
      // 请求展开 Mini App
      window.Telegram.WebApp.expand();

      if (!window.Telegram.WebApp.isFullscreen) {
        window.Telegram.WebApp.requestFullscreen();
      }
    }

    // 返回按钮点击事件
    window.Telegram.WebApp.BackButton.onClick(() => {
      window.Telegram.WebApp.close();
    });

    // 获取用户初始数据
    const initData = window.Telegram.WebApp.initDataUnsafe;
    if (initData && initData.user) {
      const { first_name, last_name, username, photo_url, id } = initData.user;
      setUser({
        name: `${first_name || ""}`,
        avatar: photo_url || "https://via.placeholder.com/50",
      });

      // 自动获取用户的 telegram_id 并发送请求到后端
      if (id) {
        fetchMonBalance(id);
      }
    }

    // 检测是否为 iOS 设备
    function isIOS() {
      return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    }

    // 设置 .logo 的 padding-top 样式
    const logoElement = document.querySelector('.logo');
    if (logoElement) {
      if (isIOS()) {
        logoElement.style.paddingTop = '30px';
      } else {
        logoElement.style.paddingTop = '10px';
      }
    }
  }, []);

  // 获取mon_balance并更新
  const fetchMonBalance = async (telegramId) => {
    try {
      const response = await fetch(`https://dev.tonchain.monster:5000/api/users/${telegramId}`);
      const data = await response.json();
      if (data && data.mon_balance) {
        setMonBalance(data.mon_balance);  // 设置返回的mon_balance
      } else {
        setMonBalance(0);  // 默认值，如果没有mon_balance
      }
    } catch (error) {
      console.error('Error fetching mon_balance:', error);
      setMonBalance(0);  // 请求失败时的默认值
    }
  };

  return (
    <div className="app">
      <div className="logo">
        <img src="https://www.tonchain.monster/image/logo.png" />
      </div>
      <header className="header">
        <div className="user-info">
          <img src={user.avatar} className="avatar" />
          <div className="user-bar">
            <span className="user-name">{user.name || "Guest"}</span>
            <img className="walletButton" src="https://www.tonchain.monster/image/wallet.png" />
          </div>
        </div>
        <div className="balance-info">
          <div className="monBalance">
            <span>{monBalance !== null ? monBalance : 'Loading...'}</span>
          </div>
          <img className="monIcon" src="https://www.tonchain.monster/image/moncoin.png" />
          <div className="tonBalance">
            <span>13.6</span>
          </div>
          <img className="tonIcon" src="https://www.tonchain.monster/image/toncoin.png" />
        </div>
      </header>

      <div className="swap">
        <img src="https://www.tonchain.monster/image/swap.png" />
      </div>

      <div className="mTeam">
        <div className="mTitle">
          <div className="teamTitle">Mining Team #1</div>
          <img src="https://www.tonchain.monster/image/change.png" />
        </div>
        <div className="mBox">
          <div className="numCard">
            <div className="mNum">
              <div className="toClaim">
                <img src="https://www.tonchain.monster/image/moncoin.png" />
                <span> 2.7357</span>
              </div>
              <div className="perHr">0.016 $MON / hour</div>
            </div>
            <div className="mCards">
              <img src="https://www.tonchain.monster/card/hawke.png" />
              <img src="https://www.tonchain.monster/card/sparu.png" />
              <img src="https://www.tonchain.monster/card/rexa.png" />
            </div>
          </div>

          <div className="claim">
            <div className="progress">
              <div className="maxCoin"><div>max 3.000 $MON</div></div>
              <div className="bar">
                <div className="barProgress"></div>
              </div>
            </div>
            <div className="claimBtn">
              <div className="btClaim">Claim</div>
            </div>
          </div>
        </div>
      </div>

      <div className="teamPlus"><div className="plusBox">+</div></div>

      <footer className="footer">
        <div className="footerBTN">
          <div className="footerMine"><img src="https://www.tonchain.monster/image/bt_mine.png" /></div>
          <div className="footerMonster"><img src="https://www.tonchain.monster/image/bt_monster.png" /></div>
          <div className="footerArena"><img src="https://www.tonchain.monster/image/bt_arena.png" /></div>
          <div className="footerFriends"><img src="https://www.tonchain.monster/image/bt_friends.png" /></div>
          <div className="footerEarn"><img src="https://www.tonchain.monster/image/bt_earn.png" /></div>
        </div>
      </footer>
    </div>
  );
}

export default App;